import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Avatar extends Component {  
    constructor(props) {
        super(props);

        this.state = {
            userImage: (this.props.avatar) ? this.props.avatar : '/images/default-avatar.png'
        }

        this.avatarStyle = {
            backgroundImage: 'url('+this.state.userImage+')'
        }
    } 

    render() {
        return(
            <Link to="/settings" className="user-account__avatar" style={this.avatarStyle}></Link>
        );
    }
}

export default Avatar;