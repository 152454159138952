import React, {Component} from 'react';
import Logo from './../Header/Logo';
import Newsletter from './../Newsletter';

class Soon extends Component {  
    render() {
        return(
            <section className="soon">
                <div className="soon__content">
                    <Logo />
                    <h1>We're developing...</h1>
                    <p>Come back soon or subscribe to our newsletter.</p>
                    <Newsletter />
                </div>                
            </section>
        );
    }
}

export default Soon;