import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';

// Page Parts
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';

// Context
import { CurrentUserProvider, CurrentUserConsumer } from './context/CurrentUser.context';

// Page Components
import NotFound from './components/404NotFound/NotFound';
import Pricing from './components/Pricing/Pricing';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Home from './components/Home/Home';
import Dashboard from './components/Dashboard/Dashboard';
import Settings from './components/Settings/Settings';

// Temporary Pages
import Soon from './components/Soon/Soon';

// Styles
import './App.scss';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <CurrentUserConsumer>
        {({user}) => (
          user ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        )}
      </CurrentUserConsumer>
    }
  />
)

class App extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     logged: false
  //   }
  // }

  render() {
    return (
      <div className="app">
        <Router>
          <CurrentUserProvider>
            <Header />
            <Main>
              <Switch>
                <Route path="/" exact={true} component={Home} />
                <Route path="/login" component={Login} />       
                <Route path="/register" component={Register} />
                <Route path="/pricing" component={Pricing} />
                <PrivateRoute path="/panel/:page?" component={Dashboard} />
                <PrivateRoute path="/settings" component={Settings} />
                <Route path="/soon" component={Soon} />
                <Route component={NotFound} />
              </Switch>          
            </Main>
            <Footer /> 
          </CurrentUserProvider>
        </Router>              
      </div>    
    );
  }  
}

export default App;