import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class UserLogin extends Component {    
    render() {
        return(
            <nav className="navigation navigation--login">
                <ul className="user-login">
                    <li className="navigation__item"><Link to="/login" className="user-login__link">Sign In</Link></li>
                    <li className="navigation__item navigation__item--button"><Link to="/register" className="user-login__link">Sign Up Free</Link></li>
                </ul>
            </nav>           
        );
    }
}

export default UserLogin;