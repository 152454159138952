import React, {Component} from 'react';
import Helmet from 'react-helmet';
import {Link} from 'react-router-dom';

class NotFound extends Component {    
    render() {
        return(
            <div className="page-error page-error--404">
                <Helmet>
                    <title>404 Page Not Found | MAKEAD</title>
                </Helmet>
                <div className="page-error__content grid">
                    <h1>404 Not Found</h1>
                    <p>Sorry, we can't find the page you're looking for.</p>
                    <p>You can either return to the previous page or visit our homepage.</p>
                    <p><Link to="/" className="btn btn--large btn--border"><span>Go to homepage</span></Link></p>
                </div>
            </div>
        );
    }
}

export default NotFound;