import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../Header/Logo';
import SocialMedia from './SocialMedia';

class Footer extends Component {    
    render() {
        return(
            <footer className="footer">
               <div className="footer__content grid">
                   <div className="footer__company">
                       <p className="footer__logo"><Logo /></p>
                       <p>sales@makead.pl</p>
                       <p>+48 111 111 111</p>
                       <SocialMedia />
                   </div>
                   <nav className="footer__navigation navigation navigation--vertical navigation--footer">
                        <ul>
                            <li className="navigation__item"><h3>About Us</h3></li>
                            <li className="navigation__item"><Link to="/soon">Product</Link></li>
                            <li className="navigation__item"><Link to="/soon">Features</Link></li>
                            <li className="navigation__item"><Link to="/soon">Plans & Pricing</Link></li>
                            <li className="navigation__item"><Link to="/soon">Testimonials</Link></li>
                        </ul>
                   </nav>
                   <nav className="footer__navigation navigation navigation--vertical navigation--footer">
                        <ul>
                            <li className="navigation__item"><h3>Customer Info</h3></li>
                            <li className="navigation__item"><Link to="/soon">Our blog</Link></li>
                            <li className="navigation__item"><Link to="/soon">F.A.Q.</Link></li>
                            <li className="navigation__item"><Link to="/soon">Contact</Link></li>
                        </ul>
                   </nav>
                   <nav className="footer__navigation navigation navigation--vertical navigation--footer">
                        <ul>
                            <li className="navigation__item"><h3>Links</h3></li>
                            <li className="navigation__item"><Link to="/soon">Terms of use</Link></li>
                            <li className="navigation__item"><Link to="/soon">Privacy policy</Link></li>
                        </ul>
                   </nav>
                   <div className="footer__copyrights">
                       <p>&copy;2019 Makead.pl All Right Reserved</p>
                   </div>
               </div>
            </footer>
        );
    }
}

export default Footer;