import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import Box from '../Box';

import Team from './Team';

class MenageTeam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editable: true,
            noToggle: false
        }        
    }

    render() {
        return(
            <section id="dashboard-manage-team" className="dashboard-manage-team">
                <Helmet>
                    <title>Manage your team - MAKEAD</title>
                </Helmet>
                <div className="dashboard-manage-team__content">
                    <div className="cg--row">
                        <div className="cg cg-10">
                            <Box title='Your team' content={<Team editable={this.state.editable} />} noToggle />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default MenageTeam;