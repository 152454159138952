import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import Box from '../Box';

import Team from './../ManageTeam/Team';

class Main extends Component {    
    render() {
        return(            
            <section id="dashboard-main" className="dashboard-main">
                <Helmet>
                    <title>Dashboard - MAKEAD</title>
                </Helmet>
                <div className="dashboard-main__content">
                    <div className="cg--row">
                        <div className="cg cg-3">
                            <Box title='Statistics' content='Just test component.' />
                            <Box title="Team members" content={<Team editable={false} />} />
                        </div>
                        <div className="cg cg-7">
                            <Box title='Statistics' content='Just test 2-component.' />
                            <Box title='Statistics' content='Hello. Its example component' />
                        </div>
                    </div>
                    <div className="cg--row">
                        <div className="cg cg-6">
                            <Box title='Statistics' content='Just test component.' />                            
                        </div>
                        <div className="cg cg-4">
                            <Box title='Statistics' content='Just test component.' />
                        </div>
                    </div>                    
                </div>
            </section>
        );
    }
}

export default Main;