import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import { CurrentUserConsumer } from './../../context/CurrentUser.context';
import {Link} from 'react-router-dom';

class UserToggleMenu extends Component {
    render() {
        return(
            <CurrentUserConsumer>
                {({logout}) => (
                <nav className="navigation navigation--vertical">
                    <ul>
                        <li className="navigation__item"><Link to="/panel/manage-team">Manage Team</Link></li>
                        <li className="navigation__item"><Link to="/settings">Account Settings</Link></li>
                        <li className="navigation__item"><a onClick={logout}>Log Out</a></li>
                    </ul>
                </nav>
                )}              
            </CurrentUserConsumer>            
        );
    }
}

export default UserToggleMenu;