import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {axios} from 'axios';

const CurrentUserContext = React.createContext();

export class CurrentUserProvider extends Component {
    state = {
        user: null,
        // user: {
        //     first_name: "Rafał",
        //     last_name: "Depa",
        // }
    }

    getUser = () => {
        if(this.state.user) {
            axios({
                method: 'post',
                url: 'https://api.makead.pl/v1/auth/validate',
                data: this.state.user.authToken
            })
            .then(function(response) {
                console.log(response)
            })
            .catch(function(error) {            
                console.log(error)
            })
        } else {
            this.login();
        }         
    }

    login = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        console.log('login');
        this.setState({
            authToken: localStorage.getItem('authToken'),
            user: {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                avatar: user.avatar,
                companyName: user.companyName,
                companyId: user.companyId,
                role: user.role
            }
        })
    }

    logout = () => {
        this.setState({ user: null });
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
    }

    render() {
        const { children } = this.props;
        return (
            <CurrentUserContext.Provider
                value={{
                    login: this.login,
                    logout: this.logout,
                    user: this.state.user
                }}
            >
                {children}
            </CurrentUserContext.Provider>
        )
    }
}

export const CurrentUserConsumer = CurrentUserContext.Consumer;