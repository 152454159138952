import React, {Component} from 'react';

class Box extends Component {
    state = {
        title: 'Default Name',
        content: 'This component is empty',
        isHidden: false,
        toggle: true
    }

    componentWillMount = () => {
        if(this.props.title && this.props.title !== this.state.title) {
            this.setState({ title: this.props.title });
        }
        if(this.props.content && this.props.content !== this.state.content) {
            this.setState({ content: this.props.content });
        }
        if(this.props.noToggle) {
            this.setState({ toggle: false });
        }
    }

    changeVisibility = () => {
        if(this.state.isHidden) {
            this.setState({ isHidden: false });
        } else {
            this.setState({ isHidden: true });
        }
    }

    render() {
        this.boxClass = "box";
        if(this.state.isHidden) { this.boxClass += ' box--hidden'}

        return(
            <div className={this.boxClass}>
                <div className="box__header">
                    <h3 className="box__title">{this.state.title}</h3>
                    {this.state.toggle
                        ? <button className="box__toggle" onClick={e => this.changeVisibility()}></button>
                        : null
                    }
                </div>
                <div className="box__content">
                    {this.state.content}
                </div>
            </div>
        );
    }
}

export default Box;