import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Hero extends Component {   
    constructor(props) {
        super(props);

        this.state = {
            title: 'We solve your problems with the advertising campaigns',
            subtitle: 'We are still developing, so come back later.',
            button: 'SIGN UP FREE',
            buttonURL: '/register'
        }
    }
    
    render() {
        return(
            <section id="hero" className="hero">
                <div className="hero__content grid">
                    <div className="hero__text">
                        <h1 className="hero__title">{this.state.title}</h1>
                        <h2 className="hero__subtitle">{this.state.subtitle}</h2>
                        <p><Link to={this.state.buttonURL} className="btn btn--large">{this.state.button}</Link></p>
                    </div>                    
                </div>
            </section>
        );
    }
}

export default Hero;