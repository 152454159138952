import React, {Component} from 'react';

class Plan extends Component {
    constructor(props) {
        super(props);

        this.price = (props.yearly) ? props.priceYearly : props.priceMo;

        this.state = {
            title: props.title,
            price: this.price,
            description: props.description,
            bestseller: props.bestseller,
            contains: props.contains,
            yearly: props.yearly
        }        
    }

    componentWillReceiveProps = (newProps) => {
        this.setState({
            price: (newProps.yearly) ? newProps.priceYearly : newProps.priceMo,
            yearly: newProps.yearly
        })
    }

    render() {
        let planClass;
        (this.state.bestseller) ? planClass = 'pricing-plans__plan pricing-plans__plan--bestseller' : planClass = 'pricing-plans__plan';

        let planPriceClass;
        (this.state.yearly) ? planPriceClass = 'pricing-plans__plan__price pricing-plans__plan__price--yearly' : planPriceClass = 'pricing-plans__plan__price pricing-plans__plan__price--monthly';

        let planContain = this.state.contains.map((item, key) => <li key={key}>{item}</li> );

        return(
            <label className={planClass}>
                <h3 className="pricing-plans__plan__title">{this.state.title}</h3>
                <p className={planPriceClass}>{this.state.price}</p>
                <p className="pricing-plans__plan__description">{this.state.description}</p>
                <ul className="pricing-plans__plan__contains">
                    {planContain}
                </ul>
            </label>
        );
    }
}

export default Plan;