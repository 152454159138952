import React, {Component} from 'react';

class Newsletter extends Component {  

    saveToNewsletter = (e) => {
        e.preventDefault();
        console.log('save');
    }

    render() {
        return(
            <div id="newsletter" className="newsletter">
                <form className="newsletter__form form">
                    <label className="form__label form__label--dual" onSubmit={e => {this.saveToNewsletter(e); e.preventDefault();}}>
                        <input type="email" name="email" className="form__input" placeholder="johnsnow@got.com" />
                        <button type="submit" className="form__btn btn"><span>SAVE</span></button>
                    </label>
                </form>
            </div>
        );
    }
}

export default Newsletter;