import React, {Component} from 'react';
import { CurrentUserConsumer } from './../../context/CurrentUser.context';
import Logo from './Logo';
import Navigation from './Navigation';
import UserLogin from './UserLogin';
import UserAccount from './UserAccount';

class Header extends Component {    
    render() {
        return(
            <CurrentUserConsumer>
                {({ user, logout }) => (
                    <header className="header">
                    {user
                        ?   <div className="header__content grid grid--full">
                                <Logo />
                                <Navigation logged={true} />
                                <UserAccount />
                            </div>
                        :   <div className="header__content grid">
                                <Logo />
                                <Navigation logged={false} />
                                <UserLogin />
                            </div>
                    }
                    </header>                    
                )}                
            </CurrentUserConsumer>
        );       
    }
}

export default Header;

// if(this.state.logged) {
        //     return(
        //         <header className="header">
        //             <div className="header__content grid grid--full">
        //                 <Logo />
        //                 <Navigation logged={this.state.logged} />
        //                 <UserAccount />
        //             </div>
        //         </header>
        //     );
        // } else {
        //     return(
        //         <header className="header">
        //             <div className="header__content grid">
        //                 <Logo />
        //                 <Navigation logged={this.state.logged} />
        //                 <UserLogin />
        //             </div>
        //         </header>
        //     );
        // }        