import React, {Component} from 'react';
import Helmet from 'react-helmet';
import Hero from './Hero';
// import Product from './Product';
// import Testimonials from './Testimonials';

class Home extends Component {  
    render() {
        return(
            <div className="home">
                <Helmet>
                    <title>MAKEAD - We solve your problems with the advertising campaigns</title>
                </Helmet>
                <Hero />
                {/* <Product />
                <Testimonials /> */}
            </div>
        );
    }
}

export default Home;