import React, {Component} from 'react';
import DashboardNav from './Navigation';

// Dashboard Pages
import Main from './Main/Main';
import MenageTeam from './ManageTeam/ManageTeam';

class Dashboard extends Component {
    state = {
        page: 'main'
    }
    
    checkPage = () => {
        let {page} = this.props.match.params;
        if(page) {
            if(page !== this.state.page) {
                this.setState({ page: page });
                this.showPage(page);
            }
        } else {
            page = 'main';
            if(page !== this.state.page) {
                this.setState({ page: page });
                this.showPage(page);
            }
        }        
    }

    showPage = (page) => {     
        switch(page) {
            case 'main':
                return <Main />;
            case 'manage-team':
                return <MenageTeam />;
            default:
                return 'Its not finished. Come back soon!';
        }
    }

    componentDidUpdate = () => {
        this.checkPage();
    }
    componentWillMount = () => {
        this.checkPage();
    }    

    render() {
        return(
            <div className="dashboard">
                <aside className="dashboard__nav">
                    <DashboardNav />
                </aside>
                <main className="dashboard__content">
                    {this.showPage(this.state.page)}
                </main>
            </div>
        );
    }
}

export default Dashboard;