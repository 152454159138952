import React, {Component} from 'react';
import Helmet from 'react-helmet';
import {Formik} from 'formik';
import axios from 'axios';

class Settings extends Component {
    state = {         
        user: {}            
    }

    componentWillMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        this.setState({
            user: {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                authToken: localStorage.getItem('authToken')
            }
        })
    }

    render() {
        return(
            <section className="settings">
                <Helmet>
                    <title>Account Settings | MAKEAD</title>
                </Helmet>
                <Formik
                    initialValues = {{...this.state.user}}
                    onSubmit={(values) => {
                        axios({
                            method: 'post',
                            url: 'https://api.makead.pl/v1/account/update',
                            data: values
                        })
                        .then(function(response) {
                            if(response.status === 201) {
                                localStorage.setItem('authToken',response.data.token);

                                let user = JSON.parse(localStorage.getItem('user'));
                                user.first_name = values.first_name;
                                user.last_name = values.last_name;
                                user.email = values.email;
                                console.log(user);
                                localStorage.setItem('user',JSON.stringify(user));

                                this.setState({ 
                                    user: {
                                        'authToken': response.data.token,
                                        'first_name': values.first_name,
                                        'last_name': values.last_name,
                                        'email': values.email
                                    }
                                })                                
                            } else {
                                alert(response.data.message)
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })                       
                    }}
                    validate={(values) => {
                        let errors = {};
                        
                        // // Validate Company Name
                        // if(!values.company_name) {
                        //     errors.company_name = "Required";
                        // } else if(values.company_name.length < 3) { 
                        //     errors.company_name = "Too short. Minimum 3 characters..";
                        // }

                        // // Validate First Name
                        // if(!values.first_name) {
                        //     errors.first_name = "Required";
                        // } else if(values.first_name.length < 3) { 
                        //     errors.first_name = "Too short. Minimum 3 characters..";
                        // }

                        // // Validate Last Name
                        // if(!values.last_name) {
                        //     errors.last_name = "Required";
                        // } else if(values.last_name.length < 3) { 
                        //     errors.last_name = "Too short. Minimum 3 characters..";
                        // }

                        // // Validate Email
                        // if(!values.email) {
                        //     errors.email = "Required";
                        // } else if(values.email.length < 3) {
                        //     errors.email = "Too short. Minimum 3 characters.."
                        // } else if(!this.validateEmail(values.email)) {
                        //     errors.email = "It's not a valid email address..."
                        // }

                        // // Validate Password
                        // if(!values.password) {
                        //     errors.password = "Required";
                        // }

                        // // Validate Re-Password
                        // if(!values.re_password) {
                        //     errors.re_password = "Required";
                        // } else if(values.password !== values.re_password) {
                        //     errors.password = "The passwords you have entered do not match.";
                        // }

                        return errors;
                    }}
                    render={({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        labelClass = 'form__label',
                        labelClassError = 'form__label form__label--error'
                    }) => (
                        <form onSubmit={handleSubmit} className="sign-form__form form form--light">                                
                            <label className="form__label form__label--two">
                                <div>
                                    <span>First Name</span>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.first_name}
                                        name="first_name"
                                        className="form__input"
                                    />               
                                    {errors.first_name && <div className="form__label__error">{errors.first_name}</div>}  
                                </div>
                                <div>
                                    <span>Last Name</span>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.last_name}
                                        name="last_name"
                                        className="form__input"
                                    />               
                                    {errors.last_name && <div className="form__label__error">{errors.last_name}</div>}  
                                </div>
                            </label>
                            <label className={errors.email ? labelClassError : labelClass}>
                                <span>E-mail</span>                                    
                                <input
                                    type="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    name="email"
                                    className="form__input"
                                    autoComplete="email"
                                />               
                                {errors.email && <div className="form__label__error">{errors.email}</div>}                     
                            </label>
                            <label className="form__label">
                                <button type="submit" className="btn">Update</button>
                            </label>
                        </form>
                        )}
                    />  
            </section>
        );
    }
}

export default Settings;