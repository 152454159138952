import React, {Component} from 'react';
// import {Redirect} from 'react-router';
import Helmet from 'react-helmet';
import {Formik} from 'formik';
import axios from 'axios';
// import Api from './../../helpers/Api';

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,           
            registerForm: {
                first_name: 'John',
                last_name: 'Snow',
                email: 'john@snow.com',
                company_name: 'GOT Sp. z o.o.',
                password: 'abc123',
                re_password: 'abc123'
            }            
        }
    }    

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        return(
            <section id="register" className="sign-form">
                <Helmet>
                    <title>Create a new account in Makead.pl</title>
                </Helmet>
                <div className="sign-form__content grid">
                    <h1 className="section-header">Sign Up</h1>
                    <Formik
                        initialValues = {{...this.state.registerForm}}
                        onSubmit={(values) => {
                            axios({
                                method: 'post',
                                url: 'https://api.makead.pl/v1/account/create',
                                data: values
                            })
                            .then(function(response) {
                                if(response.data.status === 201) {
                                    this.setState({ redirect: true })
                                    
                                } else {
                                    alert(response.data.message)
                                }
                            })
                            .catch(function(error) {
                                console.log(error)
                            })                       
                        }}
                        validate={(values) => {
                            let errors = {};
                            
                            // Validate Company Name
                            if(!values.company_name) {
                                errors.company_name = "Required";
                            } else if(values.company_name.length < 3) { 
                                errors.company_name = "Too short. Minimum 3 characters..";
                            }

                            // Validate First Name
                            if(!values.first_name) {
                                errors.first_name = "Required";
                            } else if(values.first_name.length < 3) { 
                                errors.first_name = "Too short. Minimum 3 characters..";
                            }

                            // Validate Last Name
                            if(!values.last_name) {
                                errors.last_name = "Required";
                            } else if(values.last_name.length < 3) { 
                                errors.last_name = "Too short. Minimum 3 characters..";
                            }

                            // Validate Email
                            if(!values.email) {
                                errors.email = "Required";
                            } else if(values.email.length < 3) {
                                errors.email = "Too short. Minimum 3 characters.."
                            } else if(!this.validateEmail(values.email)) {
                                errors.email = "It's not a valid email address..."
                            }

                            // Validate Password
                            if(!values.password) {
                                errors.password = "Required";
                            }

                            // Validate Re-Password
                            if(!values.re_password) {
                                errors.re_password = "Required";
                            } else if(values.password !== values.re_password) {
                                errors.password = "The passwords you have entered do not match.";
                            }

                            return errors;
                        }}
                        render={({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            labelClass = 'form__label',
                            labelClassError = 'form__label form__label--error'
                        }) => (
                            <form onSubmit={handleSubmit} className="sign-form__form form form--light">
                                <label className={errors.company_name ? labelClassError : labelClass}>
                                    <span>Company Name</span>                                    
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.company_name}
                                        name="company_name"
                                        className="form__input"
                                    />               
                                    {errors.company_name && <div className="form__label__error">{errors.company_name}</div>}                     
                                </label>
                                <label className="form__label form__label--two">
                                    <div>
                                        <span>First Name</span>
                                        <input
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.first_name}
                                            name="first_name"
                                            className="form__input"
                                        />               
                                        {errors.first_name && <div className="form__label__error">{errors.first_name}</div>}  
                                    </div>
                                    <div>
                                        <span>Last Name</span>
                                        <input
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.last_name}
                                            name="last_name"
                                            className="form__input"
                                        />               
                                        {errors.last_name && <div className="form__label__error">{errors.last_name}</div>}  
                                    </div>
                                </label>
                                <label className={errors.email ? labelClassError : labelClass}>
                                    <span>E-mail</span>                                    
                                    <input
                                        type="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        name="email"
                                        className="form__input"
                                        autoComplete="email"
                                    />               
                                    {errors.email && <div className="form__label__error">{errors.email}</div>}                     
                                </label>
                                <label className={errors.password ? labelClassError : labelClass}>
                                    <span>Password</span>                                    
                                    <input
                                        type="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        name="password"
                                        className="form__input"
                                        autoComplete="new-password"
                                    />               
                                    {errors.password && <div className="form__label__error">{errors.password}</div>}                     
                                </label>
                                <label className={errors.re_password ? labelClassError : labelClass}>
                                    <span>Re-password</span>                                    
                                    <input
                                        type="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.re_password}
                                        name="re_password"
                                        className="form__input"
                                        autoComplete="new-password"
                                    />               
                                    {errors.re_password && <div className="form__label__error">{errors.re_password}</div>}                     
                                </label>
                                <label className="form__label">
                                    <button type="submit" className="btn">Sign In</button>
                                </label>
                            </form>
                        )}
                    />                    
                </div>                
            </section>
        );
    }
}

export default Register;