import React, {Component} from 'react';

class Loading extends Component {  
    render() {
        return(
            <div className="loading">
                <div className="loading__wheel"></div>
            </div>
        );
    }
}

export default Loading;