import React, {Component} from 'react';

class SocialMedia extends Component {    
    render() {
        return(
            <div className="social-media">
                <div className="social-media__item">
                    <a href="https://www.facebook.com/MakeadPL/" target="_blank" rel="noopener noreferrer" className="social-media__icon">FB</a>
                </div>
                <div className="social-media__item">
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-media__icon">TT</a>
                </div>
                <div className="social-media__item">
                    <a href="https://instagram.com/makeadpl" target="_blank" rel="noopener noreferrer" className="social-media__icon">IG</a>
                </div>
            </div>
        );
    }
}

export default SocialMedia;