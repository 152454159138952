import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Navigation extends Component {    
    constructor(props) {
        super(props);

        this.state = {
            logged: this.props.logged,
            isMobile: false
        }
    }  

    render() {
        if(this.state.isMobile) {
            if(this.state.logged) {
                return 'Soon';
            } else {
                return(                
                    <nav className="navigation navigation--mobile">
                        <ul>
                            <li className="navigation__item"><Link to="/#product">Product</Link></li>
                            <li className="navigation__item"><Link to="/#features" className="navigation__toggle">Features</Link></li>
                            <li className="navigation__item"><Link to="/pricing">Pricing</Link></li>
                            <li className="navigation__item"><Link to="/about-us" className="navigation__toggle">Company</Link></li>
                            <li className="navigation__item"><Link to="/contact">Contact</Link></li>
                            <li className="navigation__item"><Link to="/pricing">Pricing</Link></li>
                        </ul>
                    </nav>
                );
            }
        } else {
            if(this.state.logged) {
                return(
                    <nav className="navigation">
                        <ul>
                            <li className="navigation__item"><Link to="/panel">Dashboard</Link></li>
                            <li className="navigation__item"><Link to="/panel/f1">Feature 1</Link></li>
                            <li className="navigation__item"><Link to="/panel/f2">Feature 2</Link></li>
                            <li className="navigation__item"><Link to="/panel/f3">Feature 3</Link></li>
                        </ul>
                    </nav>
                );
            } else {
                return(
                    <nav className="navigation">
                        <ul>
                            <li className="navigation__item"><Link to="/#product">Product</Link></li>
                            <li className="navigation__item navigation__item--drop">
                                <Link to="/#features" className="navigation__toggle">Features</Link>
                                <ul className="navigation__dropdown">
                                    <li className="navigation__item"><Link to="/about-us">Feature 1</Link></li>
                                    <li className="navigation__item"><Link to="/contact">Feature 2</Link></li>
                                    <li className="navigation__item"><Link to="/pricing">Feature 3</Link></li>
                                </ul>
                            </li>
                            <li className="navigation__item"><Link to="/pricing">Pricing</Link></li>
                            <li className="navigation__item navigation__item--drop">
                                <Link to="/about-us" className="navigation__toggle">Company</Link>
                                <ul className="navigation__dropdown">
                                    <li className="navigation__item"><Link to="/about-us">About Us</Link></li>
                                    <li className="navigation__item"><Link to="/contact">Contact</Link></li>
                                    <li className="navigation__item"><Link to="/pricing">Pricing</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                )
            }   
        }            
    }
}

export default Navigation;