import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class DashboardNav extends Component {    
    render() {
        return(
            <nav className="navigation navigation--vertical">
                <ul>
                    <li className="navigation__item"><Link to="/panel/create-campaign" className="btn btn--compact">Create a new campaign</Link></li>
                    <li className="navigation__item"><Link to="/panel">Dashboard</Link></li>
                    <li className="navigation__item"><Link to="/panel/f2">Feature 2</Link></li>
                    <li className="navigation__item"><Link to="/panel/f3">Feature 3</Link></li>
                </ul>
            </nav>
        );
    }
}

export default DashboardNav;