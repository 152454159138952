import React, {Component} from 'react';

import Loading from './../../Loading';

class Team extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            fetched: false,
            team: undefined
        }
    }    

    componentDidMount() {
        fetch("https://api.makead.pl/v1/account/read")
            .then(resp => resp.json())
            .then(resp => {
                this.setState({ fetched: true, team: resp.accounts })
                console.log(resp);      
                console.log(this.state)   
            })     
    }

    showUser = (user) => {
        if(!user.avatar_url) { user.avatar_url = '/images/default-avatar.png'; }
        this.avatarStyle = {
            backgroundImage: 'url('+user.avatar_url+')'
        }
        return(
            <div key={user.id} className="team__person">
                <div className="team__person__avatar" style={this.avatarStyle}></div>
                <div className="team__person__info">
                    <span className="team__person__info--name">{user.first_name} {user.last_name}</span>
                    <span className="team__person__info--role">{user.role}</span>
                </div>
                {this.state.editable
                    ? <div className="team__person__action">
                        <button className="btn" onClick={e => this.removeUser(user.id)}>DELETE</button>
                      </div>
                    : null
                }
            </div>
        );
    }

    removeUser = (idObj) => {
        alert("Temporarily unavailable");
    }

    render() {
        return(
            <div className="team">                
                {this.state.fetched 
                    ? Object.keys(this.state.team).map((item) => (
                        this.showUser({...this.state.team[item]})
                    ))
                    : <Loading />
                }
            </div>            
        );
    }
}

export default Team;