import React, {Component} from 'react';
import Helmet from 'react-helmet';

import Plan from './Plan';

class Pricing extends Component {  
    state = {
        yearly: false,
        plans: [
            { 
                id: 1, 
                title: 'Personal', 
                priceMo: '$49',
                priceYearly: '$549',
                description: 'This plan is for small companies..',
                contains: [
                    '5 Team Members',
                    'Helpful 24/7 support ',
                    'Free wifi'
                ]
            },
            { 
                id: 2, 
                title: 'Agency', 
                priceMo: '$149',
                priceYearly: '$1699',
                description: 'This plan is for medium companies..',
                bestseller: true,
                contains: [
                    '15 Team Members',
                    'extra cash 100$',
                    'free wifi',
                    'top left bottom'
                ]
            },
            { 
                id: 3, 
                title: 'Enterprise', 
                priceMo: '$249', 
                priceYearly: '$2849',
                description: 'This plan is for big companies..',
                contains: [
                    '50 Team Members',
                    'extra cash 100$',
                    'free wifi',
                    'extra something',
                    'super super pro',
                    'extra mega cool'
                ]
            },
            { 
                id: 4, 
                title: 'Custom', 
                priceMo: '$999', 
                priceYearly: '$999',
                description: 'When you need even more.',
                contains: [
                    '50 Team Members',
                    'extra cash 100$',
                    'free wifi',
                    'extra something',
                    'super super pro',
                    'extra mega cool'
                ]
            },
        ]
    }

    render() {        

        return(
            <section id="pricing" className="pricing">
                <Helmet>
                    <title>Pricing and Plans | MAKEAD</title>
                </Helmet>
                <div className="pricing__content grid">
                    <h2 className="section-title">Choose Your Perfect MAKEAD plan</h2>
                    <div className="pricing-plans-switcher">
                        {this.state.yearly
                        ? <div className="pricing-plans-switcher__label">
                            <button className="pricing-plans-switcher__option" data-period="monthly" onClick={e => this.setState({ yearly: false })}>MONTHLY</button>
                            <div className="pricing-plans-switcher__btn pricing-plans-switcher__btn--yearly" onClick={e => this.setState({ yearly: !this.state.yearly })}></div>
                            <button className="pricing-plans-switcher__option" data-period="yearly" onClick={e => this.setState({ yearly: true })}>YEARLY</button>
                          </div>
                        : <div className="pricing-plans-switcher__label">                            
                            <button className="pricing-plans-switcher__option" data-period="monthly" onClick={e => this.setState({ yearly: false })}>MONTHLY</button>
                            <div className="pricing-plans-switcher__btn" onClick={e => this.setState({ yearly: !this.state.yearly })}></div>
                            <button className="pricing-plans-switcher__option" data-period="yearly"  onClick={e => this.setState({ yearly: true })}>YEARLY</button>
                          </div>
                        }
                        
                    </div>
                    <div className="pricing-plans">
                        {this.state.plans && this.state.plans.map(plan => (
                            <Plan key={plan.id} yearly={this.state.yearly} {...plan} />
                        ))}                        
                    </div>
                </div>
            </section>
        );
    }
}

export default Pricing;