import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Avatar from './Avatar';
import UserToggleMenu from './UserToggleMenu';

class UserAccount extends Component {
    state = {
        menuToggled: false,
        user: {}
    }

    toggleMenu = (e) => {
        e.preventDefault();
        if(this.state.menuToggled) {
            this.setState({ menuToggled: false });
        } else {
            this.setState({ menuToggled: true });
        }
    }

    componentWillMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        if(user) {
            this.setState({
                user: {
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    avatar: user.avatar,
                    companyName: user.companyName,
                    companyId: user.companyId,
                    role: user.role
                }
            })
        }        
    }

    render() {
        return(
            <div className="user-account">
                <Link to="/settings" className="user-account__info">
                    <span className="user-account__info--name">{this.state.user.first_name} {this.state.user.last_name}</span>
                    <span className="user-account__info--role">{this.state.user.role}</span>
                </Link>
                <Avatar avatar={this.state.user.avatar} />
                <button onClick={e => this.toggleMenu(e)}className="user-account__settings">
                    <i className="user-account__settings__icon"></i>
                    {this.state.menuToggled
                        ? <UserToggleMenu />
                        : null
                    }                    
                </button>
            </div>
        );
    }
}

export default UserAccount;