import React, {Component} from 'react';

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {...props}
    }
    render() {
        return(
            <main className="main">
                {/* <p>{this.props.name ? 'Logged: 1' : 'Logged: 0'}</p> */}
                {this.props.children}
            </main>
        );
    }
}

export default Main;