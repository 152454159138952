import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import { CurrentUserConsumer } from './../../context/CurrentUser.context';
import Helmet from 'react-helmet';
import {Formik} from 'formik';
import axios from 'axios';

const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            action: this.props.loginAction
        }
    }

    contextLogin = () => {
        return this.state.action;
    }    

    render() {
        return(
            <Formik
                initialValues = {{
                    email: '',
                    password: ''
                }}
                onSubmit={(values) => {
                    axios({
                        method: 'post',
                        url: 'https://api.makead.pl/v1/auth/login',
                        data: values
                    })
                    .then(function(response) {
                        if(response.status === 200 && response.data.message === "Successful login.") {
                            localStorage.setItem('authToken', response.data.token);
                            localStorage.setItem('user', JSON.stringify(response.data.user));
                            this.contextLogin();
                        } else {
                            alert(response.data.message);
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    }) 
                }}
                validate={(values) => {
                    let errors = {};
                    
                    // Validate Email
                    if(!values.email) {
                        errors.email = "Required";
                    } else if(values.email.length < 3) {
                        errors.email = "Too short. Minimum 3 characters.."
                    } else if(!validateEmail(values.email)) {
                        errors.email = "It's not a valid email address..."
                    }
    
                    // Validate Password
                    if(!values.password) {
                        errors.password = "Required";
                    }
    
                    return errors;
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    labelClass = 'form__label',
                    labelClassError = 'form__label form__label--error'
                }) => (
                    <form onSubmit={handleSubmit} className="sign-form__form form form--light">
                        <label className={errors.email ? labelClassError : labelClass}>
                            <span>E-mail</span>                                    
                            <input
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                name="email"
                                className="form__input"
                                autoComplete="current-email"
                            />               
                            {errors.email && <div className="form__label__error">{errors.email}</div>}                     
                        </label>
                        <label className={errors.password ? labelClassError : labelClass}>
                            <span>Password</span>                                   
                            <input
                                type="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                name="password"
                                className="form__input"
                                autoComplete="current-password"
                            />   
                            {errors.password && <div className="form__label__error">{errors.password}</div>}                                 
                        </label>
                        <label className="form__label">
                            <button type="submit" className="btn">Log In</button>
                        </label>
                    </form>
                )}
            />
        );
    }    
}

class Login extends Component {
    render() {
        return(
            <CurrentUserConsumer>
                {({ user, login }) => (
                    <section id="login" className="sign-form">
                    {user
                        ?   <Redirect to={{ pathname: "/panel", state: { from: this.props.location } }} />
                        :   <div className="sign-form__content grid">
                                <Helmet>
                                    <title>Logging to Makead.pl</title>
                                </Helmet>
                                <h1 className="section-header">Create a new account</h1>                                   
                                <LoginForm loginAction={login} />
                            </div>                            
                    }
                    </section>                    
                )}
            </CurrentUserConsumer>               
        );
    }
}

export default Login;